@import "colors.scss";
@import "mixins.scss";

.error-page {
  max-width: unset;
  //background: #ececec;
}

.error-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include tablet {
    flex-direction: column;
  }
}
.error-info {
  margin-right: 48px;
}
.error-title {
  margin-bottom: 16px;
  font-family: MTSWideMedium;
  font-size: 24px;
  color: $dark-text;
}
.error-text {
  font-family: MTSSansRegular;
  font-size: 17px;
  color: $dark-text;
}
.error-link {
  font-family: MTSSansRegular;
  font-size: 17px;
  color: $link-blue-text;
  cursor: pointer;
  &:hover {
    color: $link-blue-text;
  }
}
.error-img-404 {
  width: 360px;
  height: 340px;
  background-image: url('#{$VUE_APP_BASE}/assets/images/errors/404.webp');
  background-size: contain;
  background-repeat: no-repeat;
}
.error-copyright {
  position: absolute;
  left: 32px;
  bottom: 14px;
  font-family: MTSSansRegular;
  font-size: 14px;
  color: $gray-text;
}
